import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        height: 50,
        backgroundColor: "primary.main",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2
      }}
    >
      <Typography variant="body2">
        © {new Date().getFullYear()} Admin Dashboard
      </Typography>
    </Box>
  );
};

export default Footer;

import React, {useContext} from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import AuthContext from '../context/AuthContext'

const Navbar = () => {
    let { logoutUser } = useContext(AuthContext)

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          Admin Dashboard
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Button color="inherit" onClick={logoutUser}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

export default {
    "included": [
        { "value": "Flights", "label": "Flights" },
        { "value": "5 Star Accomodation", "label": "5 Star Accomodation" },
        { "value": "4 Star Accomodation", "label": "4 Star Accomodation" },
        { "value": "3 Star Accomodation", "label": "3 Star Accomodation" },
        { "value": "Return Airport Transfers", "label": "Return Airport Transfers" },
        { "value": "Return Airport Transfers on Private Basis", "label": "Return Airport Transfers on Private Basis" },
        { "value": "Tours and Activities", "label": "Tours and Activities" },
        { "value": "Entrance Tickets", "label": "Entrance Tickets" },
        { "value": "Admission Fee", "label": "Admission Fee" },
        { "value": "English Speaking Guide", "label": "English Speaking Guide" },
        { "value": "Escorted Day Tour", "label": "Escorted Day Tour" },
        { "value": "Return transfers in air-conditioned coach", "label": "Return transfers in air-conditioned coach" },
        { "value": "Food and Drinks", "label": "Food and Drinks" },
        { "value": "Gratuities", "label": "Gratuities" },
        { "value": "English speaking driver", "label": "English speaking driver" },
        { "value": "Mineral Water", "label": "Mineral Water" },
        { "value": "Parking Fee", "label": "Parking Fee" },
        { "value": "Air Conditioner Car", "label": "Air Conditioner Car" },
        { "value": "Private Transport", "label": "Private Transport" },
        { "value": "Pick Up Service", "label": "Pick Up Service" },
        { "value": "Lunch", "label": "Lunch" },
        { "value": "Activities not Specified", "label": "Activities not Specified" },
        { "value": "Dinner", "label": "Dinner" },
        { "value": "Meals", "label": "Meals" },
        { "value": "Breakfast", "label": "Breakfast" },
        { "value": "VISA", "label": "VISA" },
        { "value": "GST", "label": "GST" },
        { "value": "TCS", "label": "TCS" },
        { "value": "TAXES", "label": "TAXES" },
        { "value": "All Taxes", "label": "All Taxes" },
        { "value": "Transfer in Private A/C Buses", "label": "Transfer in Private A/C Buses" },
        { "value": "Accommodation in Homestay", "label": "Accommodation in Homestay" },
        { "value": "Boat Trip as Program", "label": "Boat Trip as Program" },
        { "value": "Houseboat Package", "label": "Houseboat Package" },
        { "value": "Safari as per the itinerary", "label": "Safari as per the itinerary" },
        { "value": "Cruise as per the itinerary", "label": "Cruise as per the itinerary" },
        { "value": "Train journey in private cabin", "label": "Train journey in private cabin" },
        { "value": "Assistance on arrival", "label": "Assistance on arrival" },
        { "value": "Tips", "label": "Tips" },
        { "value": "Personal expenses", "label": "Personal expenses" },
        { "value": "Any item not mentioned on the itinerary", "label": "Any item not mentioned on the itinerary" },
        { "value": "Meals as specified", "label": "Meals as specified" },
        { "value": "5* Cruise Accommodation", "label": "5* Cruise Accommodation" },
        { "value": "4* Cruise Accommodation", "label": "4* Cruise Accommodation" },
        { "value": "International flight tickets", "label": "International flight tickets" },
        { "value": "Domestic flight tickets", "label": "Domestic flight tickets" },
        { "value": "Any personal expenses such as drinks, laundry etc", "label": "Any personal expenses such as drinks, laundry etc" },
        { "value": "Peak periods supplement", "label": "Peak periods supplement" },
        { "value": "Train Tickets", "label": "Train Tickets" },
        { "value": "Entry Fee to Any Monument, Park, Museum, Monastery or any other visiting places", "label": "Entry Fee to Any Monument, Park, Museum, Monastery or any other visiting places" },
        { "value": "Payment for service provided on personal basis", "label": "Payment for service provided on personal basis" },
        { "value": "Visa Letter", "label": "Visa Letter" },
        { "value": "Optional Excursions, Tours or Activities", "label": "Optional Excursions, Tours or Activities" },
        { "value": "Supplementary cost for festival period, long weekend or peak season", "label": "Supplementary cost for festival period, long weekend or peak season" },
        { "value": "Travel Insurance", "label": "Travel Insurance" },
        { "value": "Government Service Tax as applicable", "label": "Government Service Tax as applicable" },
        { "value": "RT-PCR Test", "label": "RT-PCR Test" },
        { "value": "Quarantine charges if any", "label": "Quarantine charges if any" }
    ],
    "not_included": [
        { "value": "Flights", "label": "Flights" },
        { "value": "5 Star Accomodation", "label": "5 Star Accomodation" },
        { "value": "4 Star Accomodation", "label": "4 Star Accomodation" },
        { "value": "3 Star Accomodation", "label": "3 Star Accomodation" },
        { "value": "Return Airport Transfers", "label": "Return Airport Transfers" },
        { "value": "Return Airport Transfers on Private Basis", "label": "Return Airport Transfers on Private Basis" },
        { "value": "Tours and Activities", "label": "Tours and Activities" },
        { "value": "Entrance Tickets", "label": "Entrance Tickets" },
        { "value": "Admission Fee", "label": "Admission Fee" },
        { "value": "English Speaking Guide", "label": "English Speaking Guide" },
        { "value": "Escorted Day Tour", "label": "Escorted Day Tour" },
        { "value": "Return transfers in air-conditioned coach", "label": "Return transfers in air-conditioned coach" },
        { "value": "Food and Drinks", "label": "Food and Drinks" },
        { "value": "Gratuities", "label": "Gratuities" },
        { "value": "English speaking driver", "label": "English speaking driver" },
        { "value": "Mineral Water", "label": "Mineral Water" },
        { "value": "Parking Fee", "label": "Parking Fee" },
        { "value": "Air Conditioner Car", "label": "Air Conditioner Car" },
        { "value": "Private Transport", "label": "Private Transport" },
        { "value": "Pick Up Service", "label": "Pick Up Service" },
        { "value": "Lunch", "label": "Lunch" },
        { "value": "Activities not Specified", "label": "Activities not Specified" },
        { "value": "Dinner", "label": "Dinner" },
        { "value": "Meals", "label": "Meals" },
        { "value": "Breakfast", "label": "Breakfast" },
        { "value": "VISA", "label": "VISA" },
        { "value": "GST", "label": "GST" },
        { "value": "TCS", "label": "TCS" },
        { "value": "TAXES", "label": "TAXES" },
        { "value": "All Taxes", "label": "All Taxes" },
        { "value": "Transfer in Private A/C Buses", "label": "Transfer in Private A/C Buses" },
        { "value": "Accommodation in Homestay", "label": "Accommodation in Homestay" },
        { "value": "Boat Trip as Program", "label": "Boat Trip as Program" },
        { "value": "Houseboat Package", "label": "Houseboat Package" },
        { "value": "Safari as per the itinerary", "label": "Safari as per the itinerary" },
        { "value": "Cruise as per the itinerary", "label": "Cruise as per the itinerary" },
        { "value": "Train journey in private cabin", "label": "Train journey in private cabin" },
        { "value": "Assistance on arrival", "label": "Assistance on arrival" },
        { "value": "Tips", "label": "Tips" },
        { "value": "Personal expenses", "label": "Personal expenses" },
        { "value": "Any item not mentioned on the itinerary", "label": "Any item not mentioned on the itinerary" },
        { "value": "Meals as specified", "label": "Meals as specified" },
        { "value": "5* Cruise Accommodation", "label": "5* Cruise Accommodation" },
        { "value": "4* Cruise Accommodation", "label": "4* Cruise Accommodation" },
        { "value": "International flight tickets", "label": "International flight tickets" },
        { "value": "Domestic flight tickets", "label": "Domestic flight tickets" },
        { "value": "Any personal expenses such as drinks, laundry etc", "label": "Any personal expenses such as drinks, laundry etc" },
        { "value": "Peak periods supplement", "label": "Peak periods supplement" },
        { "value": "Train Tickets", "label": "Train Tickets" },
        { "value": "Entry Fee to Any Monument, Park, Museum, Monastery or any other visiting places", "label": "Entry Fee to Any Monument, Park, Museum, Monastery or any other visiting places" },
        { "value": "Payment for service provided on personal basis", "label": "Payment for service provided on personal basis" },
        { "value": "Visa Letter", "label": "Visa Letter" },
        { "value": "Optional Excursions, Tours or Activities", "label": "Optional Excursions, Tours or Activities" },
        { "value": "Supplementary cost for festival period, long weekend or peak season", "label": "Supplementary cost for festival period, long weekend or peak season" },
        { "value": "Travel Insurance", "label": "Travel Insurance" },
        { "value": "Government Service Tax as applicable", "label": "Government Service Tax as applicable" },
        { "value": "RT-PCR Test", "label": "RT-PCR Test" },
        { "value": "Quarantine charges if any", "label": "Quarantine charges if any" }
    ],
    "additional_info": [
        { "value": "Confirmation will be received at time of booking", "label": "Confirmation will be received at time of booking" },
        { "value": "Not wheelchair accessible", "label": "Not wheelchair accessible" },
        { "value": "Infants must sit on laps", "label": "Infants must sit on laps" },
        { "value": "Infant seats available", "label": "Infant seats available" },
        { "value": "Not recommended for travelers with back problems", "label": "Not recommended for travelers with back problems" },
        { "value": "Not recommended for pregnant travelers", "label": "Not recommended for pregnant travelers" },
        { "value": "No heart problems or other serious medical conditions", "label": "No heart problems or other serious medical conditions" },
        { "value": "Most travelers can participate", "label": "Most travelers can participate" },
        { "value": "This is a private tour/activity. Only your group will participate", "label": "This is a private tour/activity. Only your group will participate" },
        { "value": "Travelers should have a moderate physical fitness level", "label": "Travelers should have a moderate physical fitness level" },
        { "value": "This tour/activity will have a maximum of 15 travelers", "label": "This tour/activity will have a maximum of 15 travelers" },
        { "value": "Read the Booking and Cancellation Terms & Policies", "label": "Read the Booking and Cancellation Terms & Policies" },
        { "value": "Cancellation charges shall apply as per the period of cancellation terms.", "label": "Cancellation charges shall apply as per the period of cancellation terms." },
        { "value": "Once the booking is done, a minimum 5% of the package cost to be deducted if the booking is cancelled.", "label": "Once the booking is done, a minimum 5% of the package cost to be deducted if the booking is cancelled." },
        { "value": "If postponed or preponed of the service date made on or before 30 days, the cost for the desired date of the service to be calculated on the basis of Hotel Cost and Transportation Cost of that particular time period.", "label": "If postponed or preponed of the service date made on or before 30 days, the cost for the desired date of the service to be calculated on the basis of Hotel Cost and Transportation Cost of that particular time period." }
    ],
    "travel_type": [
        { "value": "Beach", "label": "Beach" },
        { "value": "Honeymoon", "label": "Honeymoon" },
        { "value": "Short Trip", "label": "Short Trip" },
        { "value": "Group Tours", "label": "Group Tours" },
        { "value": "Luxury Travel", "label": "Luxury Travel" },
        { "value": "Tour with Cruise", "label": "Tour with Cruise" },
        { "value": "Tour with Safari", "label": "Tour with Safari" },
        { "value": "Private Journeys", "label": "Private Journeys" },
        { "value": "Wildlife Journeys", "label": "Wildlife Journeys" },
        { "value": "Guided Tours", "label": "Guided Tours" }
    ]
}
import React, { useState } from "react";
import { Tab, Tabs, Form, FloatingLabel, Container, Button } from 'react-bootstrap';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import constants from "../constants/index.js"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const AddPackagePage = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: "",
        content: "",
        customer_name: "",
        customer_number: "",
        customer_email: ""
    });

    const [pkgMetaData, setPkgMetaData] = useState({
        places_covered: "",
        price: "",
        days: "",
        offer: "",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        cancellation_policy: "",
        terms_and_condition: "",
        special_note: "",
        emergency_number: ""
    });
    const [defaultTab, setDefaultTab] = useState('tourPlan');
    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState([]);
    const [travelType, setTravelType] = useState([]);

    const [tourPlanFields, setTourPlanFields] = useState([{
        id: 1,
        label: "",
        comment: ""
    }]);
    const [flightFields, setFlightFields] = useState([{
        id: 1,
        flight_number: "",
        flight_departure_time: "",
        flight_departure_city: "",
        flight_arrival_time: "",
        flight_arrival_city: ""
    }]);
    const [hotelFields, setHotelFields] = useState([{
        id: 1,
        hotel_name: "",
        location: "",
        check_in_time: "",
        nights_stay: ""
    }]);
    const animatedComponents = makeAnimated();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleMetaDataInputChange = (event) => {
        const { name, value } = event.target;
        setPkgMetaData({ ...pkgMetaData, [name]: value });
    };

    // Function to handle adding a new field for tour plan
    const handleAddTourPlanField = () => {
        const newField = { id: new Date().getTime(), label: "", comment: "" }; // Use timestamp as unique id
        setTourPlanFields([...tourPlanFields, newField]);
    };

    // Function to handle removing a field for tour plan
    const handleRemoveTourPlanField = (id) => {
        setTourPlanFields(tourPlanFields.filter(field => field.id !== id));
    };

    // Function to handle adding a new field for flight details
    const handleAddFlightField = () => {
        const newField = {
            id: new Date().getTime(),
            flight_number: "",
            flight_departure_time: "",
            flight_departure_city: "",
            flight_arrival_time: "",
            flight_arrival_city: ""
        }; // Use timestamp as unique id
        setFlightFields([...flightFields, newField]);
    };

    // Function to handle removing a field for flight details
    const handleRemoveFlightField = (id) => {
        setFlightFields(flightFields.filter(field => field.id !== id));
    };

    // Function to handle input changes for each field in tour plan, flight details, and hotel details
    const handleTabInputChange = (id, field, value, type) => {
        if (type === "tourPlan") {
            setTourPlanFields(
                tourPlanFields.map((f) => f.id === id ? { ...f, [field]: value } : f)
            );
        } else if (type === "flightDetails") {
            setFlightFields(
                flightFields.map((f) => f.id === id ? { ...f, [field]: value } : f)
            );
        } else if (type === "hotelDetails") {
            setHotelFields(
                hotelFields.map((f) => f.id === id ? { ...f, [field]: value } : f)
            );
        }
    };

    // Function to handle adding a new field for hotel details
    const handleAddHotelField = () => {
        const newField = {
            id: new Date().getTime(), // Unique ID using timestamp
            hotel_name: "",
            location: "",
            check_in_time: "",
            nights_stay: ""
        };
        setHotelFields([...hotelFields, newField]);
    };

    const handleRemoveHotelField = (id) => {
        setHotelFields(hotelFields.filter(field => field.id !== id));
    };

    const convertDate = (date) => {
        if(!date){
            return "";
        }
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    }

    // Transform input to desired format
    const transformTourPlan = (key, value) => {
        let formatedData = value.map((element, index) => ({
            title: `tour_${index + 1}`,
            description: {
                id: element.id,
                label: element.label,
                comment: element.comment
            }
        }));

        return { key: key, value: formatedData };
    };

    const transformFlightDetails = (key, value) => {
        let formatedData = value.map((element, index) => ({
            title: `flight_${index + 1}`,
            description: {
                id: element.id,
                flight_number: element.flight_number,
                flight_departure_time: convertDate(element.flight_departure_time),
                flight_departure_city: element.flight_departure_city,
                flight_arrival_time: convertDate(element.flight_arrival_time),
                flight_arrival_city: element.flight_arrival_city,
            }
        }));

        return { key: key, value: formatedData };
    };

    const transformHotelDetails = (key, value) => {
        let formatedData = value.map((element, index) => ({
            title: `hotel_${index + 1}`,
            description: {
                id: element.id,
                hotel_name: element.hotel_name,
                location: element.location,
                check_in_time: convertDate(element.check_in_time),
                nights_stay: element.nights_stay
            }
        }));

        return { key: key, value: formatedData };
    };

    const transformMetaData = (data) => {
        if (Object.keys(data).length === 0) {
            return null;
        }
        return Object.keys(data).map((key) => ({
            key: key,
            value: data[key],
        }));
    }

    const transformMultiSelectMetaData = (data) => {
        if (data.length === 0) {
            return null;
        }
        return data.map((element) => element.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let user = localStorage.getItem('authTokens');
        if (!user) {
            alert("User not logged In");
            return;
        }

        user = JSON.parse(user);

        if (!formData.title || !formData.content || !formData.customer_name || !formData.customer_number || !formData.customer_email) {
            let alertMessage = `Title, Content, Customer Name, Customer number, Customer email are required`;
            alert(alertMessage)
            return;
        }

        let packageMainAttriutes = {
            title: formData.title,
            content: formData.content,
            customer_name: formData.customer_name,
            customer_number: formData.customer_number,
            customer_email: formData.customer_email
        }

        let packageMetaData = [];

        let tourPlanFormatedData = transformTourPlan('tourPlan', tourPlanFields);
        let flightFormatedData = transformFlightDetails('flightDetails', flightFields);
        let hotelFormatedData = transformHotelDetails('hotelFields', hotelFields);
        let metaDataFormated = transformMetaData(pkgMetaData);

        packageMetaData.push(tourPlanFormatedData);
        packageMetaData.push(flightFormatedData);
        packageMetaData.push(hotelFormatedData);
        packageMetaData = [...packageMetaData, ...metaDataFormated];
        packageMetaData.push({key: 'excluded', value: transformMultiSelectMetaData(excluded)});
        packageMetaData.push({key: 'included', value: transformMultiSelectMetaData(included)});
        packageMetaData.push({key: 'additional_info', value: transformMultiSelectMetaData(additionalInfo)});
        packageMetaData.push({key: 'travel_type', value: transformMultiSelectMetaData(travelType)});

        packageMainAttriutes['metaData'] = packageMetaData;
        console.log("packageMainAttriutes:: ", packageMainAttriutes);

        const addPackageUrl = `${process.env.REACT_APP_API_URL}/api/package`;

        try {
            const response = await axios.post(addPackageUrl, packageMainAttriutes, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    "Content-Type": "application/json",
                },
            });
            let { data } = response;
            console.log("Response Data:", data);
            if (data.code !== 200) {
                alert(data.message)
            }
            navigate('/packages');

        } catch (error) {
            console.error("Error occurred:", error.response || error.message);
            alert(error.message);
        }
        // Add API call logic here
    };

    return (
        <Container style={{
            marginTop: "90px",
            marginBottom: "90px",
            paddingLeft: "15px",
            paddingRight: "500px"
        }}>
            <h4>Add Package</h4>
            <Form onSubmit={handleSubmit} style={{
                marginTop: "40px",
                border: "1px solid lightgrey",
                padding: "20px",
                borderRadius: "10px"
            }}>


                <Form.Group className="mt-3">
                    <Form.Label htmlFor="title">Title</Form.Label>
                    <Form.Control
                        id="title"
                        name="title"
                        placeholder="title"
                        type="text"
                        value={formData.title}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="content">Content</Form.Label>
                    <Form.Control
                        id="content"
                        name="content"
                        placeholder="content"
                        as="textarea"
                        value={formData.content}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="customer_name">Customer Name</Form.Label>
                    <Form.Control
                        id="customer_name"
                        name="customer_name"
                        placeholder="Customer name"
                        type="text"
                        value={formData.customer_name}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="">Customer phone number</Form.Label>
                    <Form.Control
                        id="customer_number"
                        name="customer_number"
                        placeholder="Customer phone number"
                        type="number"
                        value={formData.customer_number}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="customer_email">Customer Email</Form.Label>
                    <Form.Control
                        id="customer_email"
                        name="customer_email"
                        placeholder="Customer email"
                        type="email"
                        value={formData.customer_email}
                        onChange={handleInputChange}
                    />
                </Form.Group>

                <Tabs
                    activeKey={defaultTab}
                    onSelect={(k) => setDefaultTab(k)}
                    className="mt-3"
                >
                    <Tab eventKey="tourPlan" title="Tour Plan" style={{ padding: "10px" }}>
                        {tourPlanFields.map((field) => (
                            <div key={field.id} className="tab-field">
                                {/* <Form.Group>
                                    <Form.Label htmlFor={`inputLabel${field.id}`}>Label</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id={`inputLabel${field.id}`}
                                        value={field.label}
                                        onChange={(e) => handleTabInputChange(field.id, "label", e.target.value, "tourPlan")}
                                    />
                                </Form.Group> */}

                                <FloatingLabel
                                    controlId={`inputLabel${field.id}`}
                                    label="Label"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Label"
                                        value={field.label}
                                        onChange={(e) => handleTabInputChange(field.id, "label", e.target.value, "tourPlan")}
                                    />
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId={`floatingTextarea${field.id}`}
                                    label="Comments"
                                    className="mt-3"
                                >
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        style={{ height: "100px" }}
                                        value={field.comment}
                                        onChange={(e) => handleTabInputChange(field.id, "comment", e.target.value, "tourPlan")}
                                    />
                                </FloatingLabel>

                                <Button style={{ backgroundColor: 'red' }} variant="danger" onClick={() => handleRemoveTourPlanField(field.id)}>
                                    Delete
                                </Button>
                            </div>
                        ))}
                        <Button style={{ backgroundColor: "skyblue" }} variant="primary" onClick={handleAddTourPlanField}>
                            Add New
                        </Button>
                    </Tab>

                    <Tab eventKey="flightDetails" title="Flight Details">
                        {flightFields.map((field) => (
                            <div key={field.id} className="tab-field">
                                <FloatingLabel
                                    controlId={`flight_number_${field.id}`}
                                    label="Flight Number"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Flight Number"
                                        value={field.flight_number}
                                        onChange={(e) => handleTabInputChange(field.id, "flight_number", e.target.value, "flightDetails")}
                                    />
                                </FloatingLabel>

                                {/* <FloatingLabel
                                    controlId={`flight_departure_time_${field.id}`}
                                    label="Departure Time"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Departure Time"
                                        value={field.flight_departure_time}
                                        onChange={(e) => handleTabInputChange(field.id, "flight_departure_time", e.target.value, "flightDetails")}
                                    />

                                </FloatingLabel> */}
                                <div className="inline-form-input">
                                    <p>Departure Time</p>
                                    <Datetime
                                        inputProps={{ placeholder: "Departure Time" }}
                                        input={true}
                                        value={field.flight_departure_time}
                                        onChange={(date) => handleTabInputChange(field.id, "flight_departure_time", date, "flightDetails")}
                                    />

                                </div>

                                <FloatingLabel
                                    controlId={`flight_departure_city_${field.id}`}
                                    label="Departure City"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Departure City"
                                        value={field.flight_departure_city}
                                        onChange={(e) => handleTabInputChange(field.id, "flight_departure_city", e.target.value, "flightDetails")}
                                    />
                                </FloatingLabel>

                                {/* <FloatingLabel
                                    controlId={`flight_arrival_time_${field.id}`}
                                    label="Arrival Time"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Arrival Time"
                                        value={field.flight_arrival_time}
                                        onChange={(e) => handleTabInputChange(field.id, "flight_arrival_time", e.target.value, "flightDetails")}
                                    />
                                </FloatingLabel> */}

                                <div className="inline-form-input">
                                    <p>Arrival Time</p>
                                    <Datetime
                                        inputProps={{ placeholder: "Arrival Time" }}
                                        input={true}
                                        value={field.flight_arrival_time}
                                        onChange={(date) => handleTabInputChange(field.id, "flight_arrival_time", date, "flightDetails")}
                                    />

                                </div>

                                <FloatingLabel
                                    controlId={`flight_arrival_city_${field.id}`}
                                    label="Arrival City"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Arrival City"
                                        value={field.flight_arrival_city}
                                        onChange={(e) => handleTabInputChange(field.id, "flight_arrival_city", e.target.value, "flightDetails")}
                                    />
                                </FloatingLabel>

                                <Button style={{ backgroundColor: 'red' }} variant="danger" onClick={() => handleRemoveFlightField(field.id)}>
                                    Delete
                                </Button>
                            </div>
                        ))}
                        <Button style={{ backgroundColor: "skyblue" }} variant="primary" onClick={handleAddFlightField}>
                            Add New
                        </Button>
                    </Tab>

                    <Tab eventKey="hotelDetails" title="Hotel Details">
                        {hotelFields.map((field) => (
                            <div key={field.id} className="tab-field">
                                <FloatingLabel
                                    controlId={`hotel_name_${field.id}`}
                                    label="Hotel Name"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Hotel Name"
                                        value={field.hotel_name}
                                        onChange={(e) => handleTabInputChange(field.id, "hotel_name", e.target.value, "hotelDetails")}
                                    />
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId={`location_${field.id}`}
                                    label="Location"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Location"
                                        value={field.location}
                                        onChange={(e) => handleTabInputChange(field.id, "location", e.target.value, "hotelDetails")}
                                    />
                                </FloatingLabel>

                                {/* <FloatingLabel
                                    controlId={`check_in_time_${field.id}`}
                                    label="Check-in Time"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Check-in Time"
                                        value={field.check_in_time}
                                        onChange={(e) => handleTabInputChange(field.id, "check_in_time", e.target.value, "hotelDetails")}
                                    />
                                </FloatingLabel> */}

                                <div className="inline-form-input">
                                    <p>Check-in Time</p>
                                    <Datetime
                                        inputProps={{ placeholder: "Check-in Time" }}
                                        input={true}
                                        value={field.check_in_time}
                                        onChange={(date) => handleTabInputChange(field.id, "check_in_time", date, "hotelDetails")}
                                    />

                                </div>

                                <FloatingLabel
                                    controlId={`nights_stay_${field.id}`}
                                    label="Number of Nights Stay"
                                >
                                    <Form.Control
                                        type="number"
                                        placeholder="Number of Nights Stay"
                                        value={field.nights_stay}
                                        onChange={(e) => handleTabInputChange(field.id, "nights_stay", e.target.value, "hotelDetails")}
                                    />
                                </FloatingLabel>

                                <Button style={{ backgroundColor: 'red' }} variant="danger" onClick={() => handleRemoveHotelField(field.id)}>
                                    Delete
                                </Button>
                            </div>
                        ))}
                        <Button style={{ backgroundColor: "skyblue" }} variant="primary" onClick={handleAddHotelField}>
                            Add New
                        </Button>
                    </Tab>
                </Tabs>

                <div className="mt-3">
                    <Form.Label htmlFor="included">Included</Form.Label>
                    <Select
                        id="included"
                        options={constants.included}
                        isMulti
                        name="included"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        components={animatedComponents}
                        onChange={setIncluded}
                    />
                </div>

                <div className="mt-3">
                    <Form.Label htmlFor="excluded">Not Included</Form.Label>
                    <Select
                        id="excluded"
                        options={constants.not_included}
                        isMulti
                        name="excluded"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        components={animatedComponents}
                        onChange={setExcluded}
                    />
                </div>

                <div className="mt-3">
                    <Form.Label htmlFor="additional_information">Additional Information</Form.Label>
                    <Select
                        id="additional_information"
                        options={constants.additional_info}
                        isMulti
                        name="additional information"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        components={animatedComponents}
                        onChange={setAdditionalInfo}
                    />
                </div>

                <div className="mt-3">
                    <Form.Label htmlFor="travel_type">Travel Type</Form.Label>
                    <Select
                        id="travel_type"
                        options={constants.travel_type}
                        isMulti
                        name="travel type"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        components={animatedComponents}
                        onChange={setTravelType}
                    />
                </div>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="places_covered">Places Covered</Form.Label>
                    <Form.Control
                        id="places_covered"
                        name="places_covered"
                        placeholder="Places covered"
                        type="text"
                        value={pkgMetaData.places_covered}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="price">Price</Form.Label>
                    <Form.Control
                        id="price"
                        name="price"
                        placeholder="Price"
                        type="text"
                        value={pkgMetaData.price}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="days">Days</Form.Label>
                    <Form.Control
                        id="days"
                        name="days"
                        placeholder="days"
                        type="text"
                        value={pkgMetaData.days}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="offer">Offer</Form.Label>
                    <Form.Control
                        id="offer"
                        name="offer"
                        placeholder="offer"
                        type="text"
                        value={pkgMetaData.offer}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="cancellation_policy">Cancellation Policy</Form.Label>
                    <Form.Control
                        id="cancellation_policy"
                        name="cancellation_policy"
                        placeholder="Cancellation Policy"
                        type="text"
                        value={pkgMetaData.cancellation_policy}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="terms_condition">Terms and Condition</Form.Label>
                    <Form.Control
                        id="terms_condition"
                        name="terms_and_condition"
                        placeholder="Terms and Condition"
                        as="textarea"
                        value={pkgMetaData.terms_and_condition}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="special_note">Special Note</Form.Label>
                    <Form.Control
                        id="special_note"
                        name="special_note"
                        placeholder="Special Note"
                        as="textarea"
                        value={pkgMetaData.special_note}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="emergency_number">Emergency number / helpline number</Form.Label>
                    <Form.Control
                        id="emergency_number"
                        name="emergency_number"
                        placeholder="Emergency number / helpline number"
                        type="number"
                        value={pkgMetaData.emergency_number}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="seo_title">SEO Title</Form.Label>
                    <Form.Control
                        id="seo_title"
                        name="seo_title"
                        placeholder="seo_title"
                        type="text"
                        value={pkgMetaData.seo_title}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="seo_description">SEO Description</Form.Label>
                    <Form.Control
                        id="seo_description"
                        name="seo_description"
                        placeholder="seo_description"
                        type="text"
                        value={pkgMetaData.seo_description}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label htmlFor="seo_keywords">SEO Keywords</Form.Label>
                    <Form.Control
                        id="seo_keywords"
                        name="seo_keywords"
                        placeholder="seo_keywords"
                        type="text"
                        value={pkgMetaData.seo_keywords}
                        onChange={handleMetaDataInputChange}
                    />
                </Form.Group>

                {/* Submit Button */}
                <Button type="submit" variant="primary" className="mt-5" size="lg">Save</Button>
            </Form>
        </Container>
    );
};

export default AddPackagePage;

import { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authTokens, setAuthTokens] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const navigate = useNavigate();

    useEffect(() => {
        const tokens = localStorage.getItem('authTokens');
        if (tokens) {
            try {
                const decodedToken = jwtDecode(JSON.parse(tokens).token);
                if (decodedToken.exp * 1000 > Date.now()) {
                    setAuthTokens(JSON.parse(tokens));
                    setUser(decodedToken);
                } else {
                    logoutUser(); // Token expired
                }
            } catch (err) {
                logoutUser(); // Invalid token
            }
        }
        setLoading(false); // Loading complete
    }, []);

    const loginUser = async (email, password) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/signin`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok) {
            localStorage.setItem('authTokens', JSON.stringify(data));
            setAuthTokens(data);
            setUser(jwtDecode(data.token));
            navigate('/');
        } else {
            alert('Invalid credentials. Please try again!');
        }
    };

    const logoutUser = () => {
        localStorage.removeItem('authTokens');
        setAuthTokens(null);
        setUser(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ user, authTokens, loginUser, logoutUser, loading }}>
            {!loading && children} {/* Render only after loading */}
        </AuthContext.Provider>
    );
};

export default AuthContext;

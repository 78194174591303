import React, { useState, useEffect } from 'react'
import { Container, Table, Badge, Button } from 'react-bootstrap';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';


const Packages = () => {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchPackages = async () => {
        try {
            let user = localStorage.getItem('authTokens');
            if (!user) {
                alert("User not logged In");
                return;
            }

            user = JSON.parse(user);

            const url = `${process.env.REACT_APP_API_URL}/api/packages`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const data = await response.data.data;
            console.log("data: ", data);

            setPackages(data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const onDelete = async (pkgId) => {
        console.log(`click on delete ${pkgId}`);

        const isConfirmed = window.confirm("Are you sure you want to delete this package?");
        if (!isConfirmed) return;

        let user = localStorage.getItem('authTokens');
        if (!user) {
            alert("User not logged In");
            return;
        }

        user = JSON.parse(user);
        setLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/api/package/${pkgId}`;
        const response = await axios.delete(url, {
            headers: { Authorization: `Bearer ${user.token}` }
        });

        const {code, data} = await response.data;
        console.log("data: ", data);
        if(code === 200) {
            navigate(0);
        }

    }
    // UseEffect to fetch data on component mount
    useEffect(() => {
        fetchPackages();
    }, []);

    const capitalize = (str) => {
        if (!str) return ''; // Handle empty or null strings
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    // Conditional rendering
    if (loading) {
        return <Container style={{
            marginTop: "90px",
            marginBottom: "90px",
            paddingLeft: "20px",
            paddingRight: "20px"
        }}>
            <p>Loading packages...</p>
        </Container>
    }

    if (error) {
        return <Container style={{
            marginTop: "90px",
            marginBottom: "90px",
            paddingLeft: "20px",
            paddingRight: "20px"
        }}>
            <p>Error fetching packages: {error}</p>
        </Container>
    }



    return (
        <Container style={{
            marginTop: "90px",
            marginBottom: "90px",
            paddingLeft: "20px",
            paddingRight: "20px"
        }}>
            <h4>Packages</h4>

            <Table striped style={{ marginTop: "40px", }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Status</th>
                        <th>Published On</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {packages ? packages.map((pkg, index) => (
                        
                            <tr key={index}>
                                <td>{++index}</td>
                                <td><Link to={`/package/edit/${pkg.id}`} style={{textDecoration: "auto"}}>{capitalize(pkg.title)}</Link></td>
                                <td>{capitalize(pkg.userData.name)}</td>
                                <td>{
                                    pkg.status ?
                                        <Badge pill bg="success">Published</Badge>
                                        :
                                        <Badge pill bg="warning" text="dark">Draft</Badge>}</td>
                                <td>{moment(pkg.created_at).format("MMM DD, YYYY")}</td>
                                <td>
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        className="me-2"
                                        onClick={() => navigate(`/package/edit/${pkg.id}`)}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        className="me-2"
                                        onClick={() => navigate(`/package/edit/${pkg.id}`)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => onDelete(pkg.id)}
                                    >
                                        Delete
                                    </Button>

                                </td>
                            </tr>
                        
                    ))
                    : 
                    <tr><td colspan="6" style={{textAlign: "center"}}>No data found</td></tr>
                }
                </tbody>
            </Table>
        </Container>
    )
}

export default Packages
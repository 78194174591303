import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './context/AuthContext'
import DashboardLayout from "./components/DashboardLayout";
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import PrivateRoute from './utils/PrivateRoute'
import AddPackagePage from './pages/AddPackagePage'
import Packages from './pages/Packages';
import EditPackagePage from './pages/EditPackagePage';

function AppContent() {
  console.log("running enviroment:: ", process.env.REACT_APP_MODE);
  
  const location = useLocation();

  // Exclude DashboardLayout for /login and /terms-and-condition routes
  const excludedRoutes = ["/login"];
  const isExcludedRoute = excludedRoutes.includes(location.pathname);

  return (
    <AuthProvider>
      {isExcludedRoute ? (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      ) : (
        <DashboardLayout>
          <Routes>
            <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path='/packages' element={<PrivateRoute><Packages/></PrivateRoute>} />
            <Route path="/package/add" element={<PrivateRoute><AddPackagePage /></PrivateRoute>} />
            <Route path="/package/edit/:packageId" element={<PrivateRoute><EditPackagePage /></PrivateRoute>} />
          </Routes>
        </DashboardLayout>
      )}
    </AuthProvider>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;

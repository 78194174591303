import React from 'react'
import {
    Box,
    Container,
    CssBaseline,
    Typography,
} from "@mui/material";

const HomePage = () => {
    return (
        <Container sx={{ height: 'calc(100vh - 128px)' }}>
            <CssBaseline />
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Typography component="h1" variant="h5">
                    Welcome to Metanoia Dashboard !
                </Typography>
            </Box>
        </Container>
    )
}

export default HomePage